import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import SundhedshusetIMG from '../IMG/SundhedshusetGrindsted.jpg';

let menuIndex = 0; 

function Hjem(props)
{
    let SetNavState = useOutletContext(); 
    
    useEffect(() => {
        SetNavState(menuIndex);
    });

    return <>
        <div className="Banner">
        <h1>DINE FØDDER BÆRER DIG IGENNEM HELE LIVET </h1>
        <h1 class="Align_top">Ferielukket 02/09 - 24/09 </h1>
        </div>
        <p>Dine fødder bærer dig igennem hele livet. Her kan du forkæle dem med en fodbehandling til gengæld. </p>
        <img src={SundhedshusetIMG} alt="Billede af Sundhedshuset Grindsted"></img>
        <p>
            Hos Din Fodplejer kan du virkelig forkæle dine fødder. Du kan blandt andet få en luksus 
            fodbehandling eller produkter som alle sammen er designet til at give dine fødder et 
            højere niveau af sundhed. 
        </p>
    </>;
}

export default Hjem 